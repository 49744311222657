import { lazy, Suspense } from 'react';
import SourceSearchField from 'SourceComponent/SearchField/SearchField.component';
import ClickOutside from 'Component/ClickOutside';
import { history } from 'Route';

import { SEARCH_CHAR_THRESHOLD } from 'Component/SearchOverlay/SearchOverlay.config';

export const SearchOverlay = lazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "category" */
        'Component/SearchOverlay'
        )
);

class SearchField extends SourceSearchField {
    // Add scroll listener in SearchField instead of in SearchOverlay
    componentDidMount() {
        if (super.componentDidMount) {
            super.componentDidMount();
        }
        window.addEventListener('scroll', this.handleScroll, { passive: true });
    }

    componentWillUnmount() {
        if (super.componentWillUnmount) {
            super.componentWillUnmount();
        }
        window.removeEventListener('scroll', this.handleScroll);
    }

    // When scrolling, simulate a click-outside by closing the overlay
    handleScroll = () => {
        this.closeSearch();
    };

    onSearchEnterPress = (e) => {
        if (e.key === 'Enter') {
            this.goToSearchResults();
        }
    };

    closeSearch = () => {
        const { onSearchOutsideClick } = this.props;
        onSearchOutsideClick();  // Simulate clicking outside
        this.setState({ showSearch: false });
    };

    goToSearchResults() {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = searchCriteria.trim().replace(/\s\s+/g, '%20');

        if (searchCriteria.length > SEARCH_CHAR_THRESHOLD) {
            history.push(`/search/${ search }`);
            hideActiveOverlay();
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    }

    handleOnClickCloseButton = () => {
        this.onClearSearchButtonClick();
    };

    renderContent() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            isVisible
        } = this.props;

        return (
            <>
                <input
                    id="search-field"
                    ref={ this.searchBarRef }
                    block="SearchField"
                    elem="Input"
                    onFocus={ onSearchBarFocus }
                    onChange={ this.handleChange }
                    onKeyDown={ this.onSearchEnterPress }
                    value={ searchCriteria }
                    mods={ { isActive } }
                    autoComplete="off"
                    placeholder={ __('Search products') }
                />
                <button
                    block="SearchField"
                    elem="Close"
                    mods={{ isVisible: searchCriteria.length > 0 }}
                    onClick={ this.handleOnClickCloseButton }
                >
                    {/* SVG markup */}
                </button>
                <button
                    block="SearchField"
                    elem="Button"
                    onClick={ () => this.goToSearchResults() }
                >
                    {/* SVG markup */}
                </button>
            </>
        );
    }

    render() {
        const {
            onSearchOutsideClick,
            searchCriteria,
            isVisible,
            isActive
        } = this.props;

        return (
            <div block="SearchField" mods={{ isVisible, isActive }}>
                <ClickOutside onClick={ onSearchOutsideClick }>
                    <div block="SearchField" elem="Wrapper">
                        { this.renderContent() }
                        <Suspense fallback={ this.renderOverlayFallback() }>
                            <SearchOverlay
                                clearSearch={ this.clearSearch }
                                searchCriteria={ searchCriteria }
                                hideActiveOverlay={ this.closeSearch } />
                        </Suspense>
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default SearchField;
